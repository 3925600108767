import React from 'react';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

type MetaProps = JSX.IntrinsicElements['meta'];

export interface SEOProps {
  title: string;
  description?: string;
  meta?: MetaProps[];
  keywords?: string[];
  image?: string;
}

const SEO: React.FC<SEOProps> = ({
  title,
  description,
  meta = [],
  keywords,
  image,
}) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            twitter
            logo
          }
        }
      }
    `
  );
  const metaTags = [
    { name: 'description', content: description },
    { property: 'og:type', content: 'website' },
    { name: 'og:title', property: 'og:title', content: title },
    {
      name: 'og:description',
      property: 'og:description',
      content: description,
    },
    { property: 'og:site_name', content: site.siteMetadata.title },
    {
      property: `og:type`,
      content: `website`,
    },
    {
      property: `og:image`,
      content: image || site.siteMetadata.logo,
    },
    { name: 'twitter:site', content: site.siteMetadata.twitter },
    { name: 'twitter:card', content: 'summary' },
    {
      name: `twitter:creator`,
      content: '@dewebgineer',
    },
    { name: 'twitter:title', content: title },
    { name: 'twitter:description', content: description },
    {
      name: 'twitter:image',
      content: image || site.siteMetadata.logo,
    },
    { name: 'twitter:image:alt', content: 'Dewebgineer Solutions' },
    ...meta,
  ].concat(
    keywords && keywords.length > 0
      ? {
          name: 'keywords',
          content: keywords.join(', '),
        }
      : []
  );

  return (
    <Helmet
      htmlAttributes={{
        lang: 'en',
      }}
      title={title}
      titleTemplate={`%s | Dewebgineer Solutions`}
      meta={metaTags}
    />
  );
};

export default SEO;
